.before-poll-started {
  width: 100% !important;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  align-items: baseline !important;

  .description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 95%;
  }
  .ant-tag {
    background-color: transparent !important;
    padding: 6px 2px 6px 10px;
  }
}

.mobile-before-poll-started {
  width: 100% !important;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  align-items: baseline !important;

  .description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 95%;
  }
  .ant-tag {
    background-color: transparent !important;
    padding: 6px 2px 6px 10px;
  }
}

.mobile-poll-row{
  width: 100%;
  border: 1px solid #d3d3d3;
  height: 5vh;
  display: flex;
  align-items: baseline;
  margin-bottom: 2vh;
  border-radius: 4px;
  /* max-height: 12.8vh; */
  overflow-y: overlay;
  height: fit-content;
  overflow-x: hidden;
  padding-top: 0.7vh;
  padding-right: 2vw;
  .options {
    background: transparent;
  }

  .ant-space {
    .ant-space-item {
      .description {
        width: 48.5vw !important;
      }
    }
  }
}

.mobile-correct-answer {
  width: 100%;
  background-color: #e2eafd !important;
  border: 1px solid #1565dc;
  height: 5vh;
  display: flex;
  align-items: baseline;
  margin-bottom: 2vh;
  border-radius: 4px;
  /* max-height: 12.8vh; */
  overflow-y: overlay;
  height: fit-content;
  overflow-x: hidden;
  padding-top: 0.7vh;
  padding-right: 2vw;
  .options {
    background: transparent;
  }

  .ant-space {
    .ant-space-item {
      .description {
        width: 48.5vw !important;
      }
    }
  }
}

.poll-row,
.correct-answer {
  width: 100%;
  border: 1px solid #d3d3d3;
  height: 5vh;
  display: flex;
  align-items: baseline;
  margin-bottom: 2vh;
  border-radius: 4px;
  max-height: 12.8vh;
  overflow-y: overlay;
  height: fit-content;
  overflow-x: hidden;
  padding-top: 0.7vh;

  .description {
    width: 91%;
  }
}

.total-votes {
  opacity: 0.7;
}

.poll-row .ant-space,
.correct-answer .ant-space {
  width: 100%;
}

.poll-row .ant-space-item,
.correct-answer .ant-space-item {
  width: 100%;
}

.poll-row .ant-space-item,
.correct-answer .ant-space-item {
  display: flex;
}

.poll-row .ant-space-item .votes,
.correct-answer .ant-space-item .votes {
  width: 15%;
  display: flex;
  justify-content: flex-end;
  padding-right: 1vw;
  font-weight: bold;
}
.change-votes-color {
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  color: #1565dc;
}
.mobile-change-votes-color {
  width: 18%;
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  color: #1565dc;
}

.poll-row .options,
.correct-answer .options {
  background: transparent !important;
  color: #1565dc;
}
.answer-marker {
  margin-right: 0.5vw;
  margin-left: 0.5vw;
}
.correct-option {
  background: none !important;
  color: #1565dc;
}

.correct-answer {
  background-color: #e2eafd !important;
  border: 1px solid #1565dc;
  .tick {
    display: flex;
    margin-left: 0.5vw;
  }
}

.parent-after-poll-started{
    width: 100%;
}

.after-poll-started{
    width: 100%;
    margin-left: -0.5vw;
    .ant-space-item{
    width: 100%;
    display: flex;
    align-items: baseline;
    .ant-radio-wrapper {
    border: 1px solid #D3D3D3;
      max-height: 12.8vh;
      overflow: overlay;
      margin-left: 0.5vw;
      padding-top: 1vh;
      margin-bottom: 1vh;
      width: 100%;
      display: flex;
      align-items: top;
      align-items: self-start;
      padding-left: 1vw;
      padding-bottom: 1vh;
      .ant-radio{
        display: none;
      }
    }
  }
}

.mobile-after-poll-started {
  .ant-space-item {
    border: 1px solid #d3d3d3;
    width: unset;
    display: flex;
    align-items: baseline;
    .ant-radio-wrapper {
      max-height: 12.8vh;
      overflow: overlay;
      margin-left: 0.5vw;
      padding-top: 1vh;
      margin-bottom: 1vh;
    }
  }
}
.mobile-view{
 display: flex;
 align-items: center;
 justify-content: flex-end;
 gap: 10px;
 margin-bottom: -4vh;
 padding-top: 1vh;
}
.web-view{
  display:flex;
  align-items: center;
  gap:10px;
  position:absolute;
  right:2px;
  bottom:0;
  padding: 6px 12px;
  margin-bottom:10px;
  width:fit-content;
}

.crousel{
  width: fit-content;
    margin-left: 92%;
}
.slide-detail-card{
  border-bottom: none;
}
.action-btns{
  display: flex;
  justify-content: flex-end;
  width: 2%;
  align-items: center;
}

.option.selected {
  border: 1px solid #1565DC !important;
  background-color: #e2eafd !important;
  
}