.span-all {
  grid-column: e("1 / -1");
}

.span2 {
  grid-column: span 2;
}

.block {
  display: block;
}

small,
.small {
  font-size: 85%;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-upcase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.text-left {
  text-align: start;
}

.text-right {
  text-align: end;
}

.text-center {
  text-align: center;
}

.align-self-center {
  align-self: center;
}

.fw-600,
.semiBold {
  font-weight: 600;
}

.fw-500,
.fw-medium {
  font-weight: 500;
}

.fw-400,
.fw-normal {
  font-weight: 400;
}

.fw-700,
.bold {
  font-weight: 700;
}

.text-color-gray {
  color: @orange-6;
}

.gray-900 {
  color: @gray900;
}

.gray-800 {
  color: @gray800;
}

.gray-700 {
  color: @gray700;
}

.gray-600 {
  color: @gray600;
}

.gray-500 {
  color: @gray500;
}

.gray-400 {
  color: @gray400;
}

.gray-300 {
  color: @gray300;
}

.gray-200 {
  color: @gray200;
}

.gray-100 {
  color: @gray100;
}

.link-color {
  color: @accent-color-alt;
}

.fs12 {
  font-size: 12px;
}

.fs14 {
  font-size: 14px;
}

.fs16 {
  font-size: 16px;
}

.m-0 {
  margin: 0 !important;
}

.flexNowrap {
  flex-wrap: nowrap !important;
}

.pointer {
  cursor: pointer;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-grid {
  display: grid;
}

.w-100 {
  width: 100%;
}

.bulletSpacer {
  flex-wrap: wrap;
  gap: 2px 8px !important;
}

// Generated margins and padding utility classes
@numList: 0 4 8 12 16 20 24 32 40 48 54;
each(@numList, {
  .mbe-@{value} {
    margin-block-end: if(@value = 0, @value, @value * 1px) !important;
  }
  .p-@{value} {
    padding: if(@value = 0, @value, @value * 1px) !important;
  }
  .pi-@{value} {
    padding-inline: if(@value = 0, @value, @value * 1px) !important;
  }
  .pb-@{value} {
    padding-block: if(@value = 0, @value, @value * 1px) !important;
  }
});

@fontSizes: 12 14 16 18;
each(@fontSizes, {
  .fz-@{value} {
    font-size: @value * 1px;
  }
});
