:root {
  font-size: @font-size-base;
}

body {
  min-height: 100vh;
}

.text-link {
  color: @accent-color;

  &:hover {
    color: @accent-color;
  }
}

.quill {
  height: 92.8%;
}

.ql-container {
  overflow: scroll;
}

.unhide-quill-toolbar {
  .ql-toolbar {
    border-color: #f0f0f0;
  }
  .ql-snow {
    border: 1px solid #f0f0f0 !important;
  }
  .ql-container {
    border: 1px solid #f0f0f0 !important;
  }
}

.hide-quill-toolbar {
  .ql-toolbar {
    height: 0;
    visibility: hidden;
    padding: 0;
  }
  .ql-container {
    border: 1px solid #f0f0f0 !important;
  }
}

.ql-editor {
  font-size: 16px;
}

.quill.hide-quill-toolbar {
  height: 100%;
  .ql-container {
    height: 100%;
    border: 1px solid #f0f0f0 !important;
  }
  .ql-toolbar {
    height: 0;
    visibility: hidden;
    padding: 0;
    border: 1px solid #f0f0f0 !important;
  }
}

// .text-green {
//   color: @accent-color;
//   &:hover {
//     color: @accent-color;
//   }
// }

.pf {
  //prefixed for specificity
  .app-root,
  .ant-layout {
    min-height: inherit;

    &-content {
      display: flex;
      flex-direction: column;
    }
  }

  .ant-tag {
    border: 0;
  }

  // Ant Tabs
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 2px;
  }

  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding: 12px 0;
  }
  .ant-tabs-tab .anticon {
    margin-right: 2px;
  }

  .ant-card-head .ant-tabs-top {
    font-weight: 600;
  }
  .ant-card-body {
    padding: 14px;
  }

  .ant-form {
    legend {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      color: @base-color;
      font-weight: 600;
      padding-block-end: 6px;
      margin-block-end: 24px;
    }
  }

  .ant-select {
    color: @base-color;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-input-status-error {
    color: @error-color;
  }

  // Ant Modal
  .ant-modal-title {
    font-weight: 600;
  }

  .ant-modal-body {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
  }

  .ant-modal-footer .ant-alert {
    padding-inline: 32px;
    text-align: left;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
    &::before {
      display: none;
    }

    // Colon ":" was not required as in design,
    // but asterisk "*" required on right side as in design.
    &::after {
      content: "*";
      display: inline-block;
      margin-inline-start: 4px;
      color: @error-color;
      font-size: 16px;
      font-family: "SimSun", sans-serif;
      line-height: 1;
    }
  }
  .ant-table {
    .ant-table-tbody > tr {
      border: none;
    }
  }

  .tableStartSpace {
    padding-inline-start: 24px;
  }

  .tableStartSpaceExtra {
    padding-inline-start: 3%;
  }

  // .ant-table-header {
  //   border-radius: 10px 10px 0 0;
  // }

  .ant-table-tbody {
    .ant-checkbox-inner {
      border-color: black;
    }
  }

  .ant-table-row-indent {
    margin-inline-end: -8px; // compensating for the flexbox gap
  }
  .ant-table-tbody {
    .ant-table-row:last-child {
      .ant-table-cell {
        &:last-child {
          border-bottom-right-radius: 10px;
        }
        &:first-child {
          border-bottom-left-radius: 10px;
        }
      }
    }
  }

  // Pagination
  .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-color: @gray400;
  }

  .ant-pagination-options {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 36px;
      border-color: @gray400;

      .ant-select-selection-item {
        line-height: 34px;
      }
    }
  }

  .ant-upload {
    &.ant-upload-drag .ant-upload {
      padding: 12px 0;
    }

    &.ant-upload-drag {
      background: #fff;
      border-style: solid;
    }
  }

  .tableStartSpace {
    padding-inline-start: 24px;
  }

  .tableStartSpaceExtra {
    padding-inline-start: 7%;
  }

  // .ant-drawer-header {
  //   border-bottom: none;
  // }

  .ant-drawer-header-title {
    flex-direction: row-reverse;

    .ant-drawer-close {
      margin-right: 0;
    }
  }

  .ant-badge-dot {
    box-shadow: 0 0 0 2px #fff;
  }

  .ant-rate-star:not(:last-child) {
    margin-right: 2px;
  }

  .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    background-color: @primary-light;
  }

  .h-auto {
    height: auto;
  }
}

.table-active_sale-row-bg {
  background-color: #fcfbf4 !important;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 5px;
    min-height: 30px;
    background-color: #d3c786;
    display: inline-block;
    left: 0;
    top: 0;
    bottom: 1px;
  }
}

.table-highlight-row-bg {
  background-color: #eaeaea !important;
  position: relative;
}

.new {
  display: inline-block;
  background-color: @error-color;
  color: #fff !important;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 2px 4px;
  margin-left: 5px;
  margin-right: 2px;
  font-size: 8px;
}

.shine {
  background: linear-gradient(
      120deg,
      #0000 33%,
      rgb(255 255 255 / 0.4) 50%,
      #0000 66%
    )
    @error-color;
  background-size: 300% 100%;
  animation: shine 2s infinite;
}

@keyframes shine {
  0% {
    background-position: right;
  }
}

.img-bordered {
  border: 1px solid @gray500;
  border-radius: @border-radius-base;
  padding: 8px;
}

.ant-menu .ant-menu-item {
  border-radius: 7px;
}

// .ant-collapse-item-active > .ant-collapse-header {
//   background-color: #dbe9ff;
//   color: #ffffff;
// }

.ant-collapse {
  border: none !important;
  .ant-collapse-item {
    border: none;
  }
}
.ant-collapse-content {
  border: none !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
}
.ant-collapse-item {
  border: none !important;
}

.ant-collapse-header {
  padding: 0px 20px 0px !important;
}

.ant-carousel {
  // width: 50%;
  padding: 10px;
  .slick-slide {
    // display: flex;
    // justify-content: center;
    // border-radius: 20px;
  }
}
// .ant-carousel .slick-slide {
//   display: flex;
//   justify-content: center;
//   border-radius: 20px;
// }
// .carousel-img:hover {
//   border-radius: 20px; /* You can adjust the value to get the desired border radius */
// }

// .ant-image-mask {
//   mask: none;
//   // border-radius: 20px //image hover border radius;;
// }

.ant-badge-status-dot {
  width: 10px !important;
  height: 10px !important;
}

.ql-tooltip {
  left: 0 !important;
}

.ant-select-multiple .ant-select-selection-item {
  border-radius: 30px !important;
  color: #1565dc !important;
  background-color: #c4d8f5 !important;
  border: 1px solid #c4d8f5 !important;
  svg {
    color: #1565dc !important;
  }
}

.slide-detail-card {
  width: 100% !important;
}

.listings-content-adjustments {
  display: flex !important;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}
