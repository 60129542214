p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.content {
  height: 9vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important; /* For Firefox */
}
