.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]:before {
  content: "Huge";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]:before {
  content: "Large";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]:before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]:before {
  content: "Small";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]:before {
  content: "Very Small";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]:before {
  content: "Tiny";
}
